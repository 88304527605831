.main-layout {
    min-width: 390px;

    &-header {
        position: relative;
        width: 100%;
        height: 80px;
        z-index: 0;
        background: #444193;
        // justify-content: space-between;
        // align-items: center;

        .navbar-brand {
            position: absolute;
            display: inline-flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-decoration: none;

            &-icon {
                display: inline-block;
                width: 32px;
                height: 32px;
                margin-right: 12px;
            }

            &-title {
                color: #fff;
                display: inline-block;
                font-family: Roboto;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 150%;
                text-transform: capitalize;
            }
        }
    }

    .content {
        // min-height: calc(100vh - 60px);
        margin-top: 40px;
    }
}