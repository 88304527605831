.faucet-info {
    color: #626262;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    text-align: justify;
    display: block;

    .address {
        color: #1B57F0;
        text-decoration: none;
        word-break: break-all;

        &:hover {
            color: #181818;
        }
    }
}