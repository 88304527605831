.orai-faucet {
    max-width: 60%;
    margin: auto;
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    
    .field {
        &-title {
            margin-bottom: 6px;
            color: #181818;
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 150%;
        }

        &-input {
            margin-bottom: 14px;
        }

        .text-field {
            box-sizing: border-box;
            width: 100%;
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;
            padding: 10px;
            color: #181818;
            background: #FFFFFF;
            border: 1px solid #E7E7E7;
            border-radius: 5px;

            &:focus-visible {
                outline: none;
            }
        }

        .followField{
            text-align: right;
        }

        .followButton {
            padding: 5px 8px;
            margin-left: 5px;
            cursor: pointer;
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 150%;
            color: #FFFFFF;
            background-color: #7664e4;
            border-color: transparent;
            border-radius: 6px;
            display: inline-flex;
            align-items: center;
            text-decoration: none;
        }
        
        .followLogo{
            height: 18px;
            width: 18px;
        }
    
        .follow{
            margin-left: 5px;
        }
    }

    .button {
        padding: 5px 8px;
        cursor: pointer;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        color: #FFFFFF;
        background: #7664e48a;
        border-color: transparent;
        border-radius: 6px;

        &-active {
            background: #7664e4;
        }

        &:focus {
            outline: none;
        }
    }

    .running {
        margin: 5px auto;
        border: 5px solid #f3f3f3;
        border-top: 5px solid #7664e4;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        animation: spin 2s linear infinite;
    }

    .faucet-message {
        margin: 30px 0;
        font-family: Source Sans Pro;
        font-style: normal;
        font-size: 18px;

        &-error {
            padding: 20px 24px;
            background-color: #fb6db51a;
            border-left: 3px solid #ff002d;
            border-radius: 5px;
            color: #cd0930;
        }

        &-success {
            padding: 20px 24px;
            background-color: #6dfb781c;
            border-left: 3px solid #00ff14;
            border-radius: 5px;
            color: #181818;
        }

        .message {
            &-title {
                font-weight: 600;
            }

            &-detail {
                .txhash {
                    color: #181818;
                }
            }
        }
    }

    .quiz{
        box-sizing: border-box;
        border: 1px solid #626262;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 5px;
        border-radius: 10px;
    }

    .quizTitle{
        color: #181818;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        list-style-type: none;
        margin-top: 5px;
        margin-bottom: 5px;
        cursor: pointer;
        box-sizing: border-box;
        border-bottom: 1px solid #626262;
    }

    .quizAns{
        padding-top: 8px;
        color: #626262;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        display: block;
        @media only screen and (min-width: 780px) {
            text-align: justify;
        }
    }

    .hyperLink{
        color: #1B57F0 ;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}

@media only screen and (max-width: 780px) {
    .orai-faucet {
        .field {
            .followField{
                text-align: center;
                margin-bottom: 10px ;
            }
            .followButton {
                margin-left: 8px;
                font-size: 15px;
            }
            
            .followLogo{
                height: 16px;
                width: 16px;
            }
            .follow{
                margin-left: 2px;
            }
        }
    }
  }